<template>
  <MainLayout>
    <template v-slot:navbar-title>
      Редактировать
    </template>
    <CreateFormLayout>
      <template v-slot:title__text>
        Пользователь
      </template>

      <template v-slot:title-flex-right>
        <div></div>
      </template>

      <template>
        <!--    Данные компании    -->
        <div>
          <FormCardTitle class="data__text">Личные данные</FormCardTitle>

          <FormInputBlock>
            <ValidationInputField
              label="Телефон"
              :disabled="currentRole != 70 && currentRole != 60"
              validate-name="телефон"
              rules="required"
              v-model="phone_number"
              mask="+7 (9##) ###-##-##"
            />

            <ValidationInputField
              label="Пользователь"
              validate-name="пользователь"
              :rules="'min:1|max:40'"
              v-model="user"
            />
            <ValidationInputField
              label="Дом/корпус"
              validate-name="дом/корпус"
              :rules="'min:1|max:40'"
              v-model="house"
            />
            <ValidationInputField
              label="Квартира/офис"
              validate-name="квартира/офис"
              :rules="'min:1|max:40'"
              v-model="houseNumber"
            />
            <ValidationInputField
              label="Номер авто"
              validate-name="номер авто"
              :rules="'min:1|max:40'"
              v-model="carNumber"
            />

            <ValidationInputField
              label="Комментарий"
              validate-name="комментарий"
              :rules="'min:1|max:40'"
              v-model="comments"
            />
          </FormInputBlock>
        </div>
        <!--    Данные компании    -->
      </template>
      <template v-slot:btns="{ handleSubmit }">
        <a @click="$router.go(-1)" class="btn btn_color_white">
          Отмена
        </a>
        <v-btn
          type="submit"
          @click="handleSubmit(editUser)"
          class="btn btn-green"
        >
          Сохранить
        </v-btn>
      </template>
    </CreateFormLayout>

    <v-overlay :value="loading" z-index="9999">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </MainLayout>
</template>

<script>
import MainLayout from "@/components/layouts/MainLayout";
import CreateFormLayout from "@/components/layouts/CreateFormLayout";
import FormInputBlock from "@/components/createFormBlocks/FormInputBlock";
import ValidationInputField from "@/components/ValidationField/ValidationInputField";

import FormCardTitle from "@/components/createFormBlocks/FormCardTitle";
import {
  actionMessage,
  successMessageDictionary
} from "@/helpers/dictionariesHelper/successMessages";

export default {
  name: "EditUserPageFromObjects",
  components: {
    FormCardTitle,
    ValidationInputField,
    FormInputBlock,
    CreateFormLayout,
    MainLayout
  },
  data() {
    return {
      loading: false,
      objectId: null
    };
  },

  created() {
    this.objectId = localStorage.getItem("ObjectId");
    this.$store.dispatch("prepareEditUserOnObject", {
      id: this.$route.params.id
    });
  },

  computed: {
    currentRole() {
      return this.$store.getters.getCurrentRole;
    },

    phone_number: {
      get() {
        return this.$store.getters.getUserEditOnObjectForm.phone_number.value;
      },
      set(newValue) {
        this.$store.commit("changeEditUserOnObjectValue", {
          fieldName: "phone_number",
          value: newValue
        });
      }
    },
    user: {
      get() {
        return this.$store.getters.getUserEditOnObjectForm.user.value;
      },
      set(newValue) {
        this.$store.commit("changeEditUserOnObjectValue", {
          fieldName: "user",
          value: newValue,
          id: localStorage.getItem("UserFieldId")
        });
      }
    },
    house: {
      get() {
        return this.$store.getters.getUserEditOnObjectForm.house.value;
      },
      set(newValue) {
        this.$store.commit("changeEditUserOnObjectValue", {
          fieldName: "house",
          value: newValue,
          id: localStorage.getItem("HouseFieldId")
        });
      }
    },
    houseNumber: {
      get() {
        return this.$store.getters.getUserEditOnObjectForm.houseNumber.value;
      },
      set(newValue) {
        this.$store.commit("changeEditUserOnObjectValue", {
          fieldName: "houseNumber",
          value: newValue,
          id: localStorage.getItem("ApartmentFieldId")
        });
      }
    },
    carNumber: {
      get() {
        return this.$store.getters.getUserEditOnObjectForm.carNumber.value;
      },
      set(newValue) {
        this.$store.commit("changeEditUserOnObjectValue", {
          fieldName: "carNumber",
          value: newValue,
          id: localStorage.getItem("CarNumberFieldId")
        });
      }
    },
    comments: {
      get() {
        return this.$store.getters.getUserEditOnObjectForm.comments.value;
      },
      set(newValue) {
        this.$store.commit("changeEditUserOnObjectValue", {
          fieldName: "comments",
          value: newValue,
          id: localStorage.getItem("CommentsFieldId")
        });
      }
    }
  },
  methods: {
    editUser() {
      this.loading = true;
      this.$store
        .dispatch("editUserOnObject", {
          id: this.$route.params.id,
          objId: this.objectId
        })
        .then(() => {
          this.$store.dispatch(
            "successNotification",
            actionMessage.edit(successMessageDictionary.user)
          );
        })
        .finally(() => (this.loading = false));
    }
  }
};
</script>

<style scoped></style>
